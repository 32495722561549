import { FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import { AccessTokenHandler } from "./CurlCommandChilds/AccessTokenHandler.tsx";
import { CurlCommandGenerator } from "./CurlCommandChilds/CurlCommandGenerator.tsx";

interface CurlCommandProps {
  selectedServer: { url: string; description: string } | null;
  selectedPath: string | null;
  selectedMethod: string | null;
  selectedParameters: any[];
  selectedResponses: any[];
  methods: Record<string, any>;
}

// Main CurlCommand component
export const CurlCommand: FunctionalComponent<CurlCommandProps> = ({
  selectedServer,
  selectedPath,
  selectedMethod,
  selectedParameters,
  selectedResponses,
  methods,
}) => {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);


  // Handle access token and authentication status via AccessTokenHandler component
  return (
    <div class="relative mt-4 mb-4 p-4 bg-white shadow rounded-md">
      <h5 class="font-bold mb-2">Generated curl Command (for Demo):</h5>
      
      {/* Handles access token authentication */}
      <AccessTokenHandler setIsAuthenticated={setIsAuthenticated} />
      
      {/* Generates the curl command based on selected parameters */}
      <CurlCommandGenerator
        selectedServer={selectedServer}
        selectedPath={selectedPath}
        selectedMethod={selectedMethod}
        selectedParameters={selectedParameters}
        selectedResponses={selectedResponses}
        methods={methods}
        isAuthenticated={isAuthenticated}
      />
    </div>
  );
};
